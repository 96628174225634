import type { FormPostSubmitFields } from "../../translation-keys/post-submit-text-keys.js";

const BlindHeading ="Welcome Back!";
const BlindSubmitBody ="양식을 기입해 주셔서 감사합니다. 아래를 클릭하여 지금 직접 다운로드할 수 있습니다.";
const Heading ="관심을 가져 주셔서 감사합니다";
const Body ="We have sent an email to the provided address with a download link to the requested asset. You may also click the button below to start your download.";
const Download = "다운로드 받기";
const PostFormFieldMap: FormPostSubmitFields = {
  "document-BlindSubmitHeading": BlindHeading,
  "document-BlindSubmitBody": BlindSubmitBody,
  "document-Heading": "양식을 기입해 주셔서 감사합니다. 문서가 첨부된 이메일을 보내드렸습니다. 아래를 클릭하여 지금 직접 다운로드할 수 있습니다.",
  "document-Body": "",
  "document-Download": Download,
  "document-landing-page-BlindSubmitHeading": "양식을 기입해 주셔서 감사합니다. 문서가 첨부된 이메일을 보내드렸습니다. 아래를 클릭하여 지금 직접 다운로드할 수 있습니다.",
  "document-landing-page-BlindSubmitBody": "지금 다운로드",
  "document-landing-page-Heading": "양식을 기입해 주셔서 감사합니다. 문서가 첨부된 이메일을 보내드렸습니다. 아래를 클릭하여 지금 직접 다운로드할 수 있습니다.",
  "document-landing-page-Body": "양식을 기입해 주셔서 감사합니다. 문서가 첨부된 이메일을 보내드렸습니다. 아래를 클릭하여 지금 직접 다운로드할 수 있습니다.",
  "document-landing-page-Download": Download,
  "post-event-assets-BlindSubmitHeading": BlindHeading,
  "post-event-assets-BlindSubmitBody": BlindSubmitBody,
  "post-event-assets-Heading": Heading,
  "post-event-assets-Body": "",
  "post-event-assets-Download": Download,
  "ppc-BlindSubmitHeading": BlindHeading,
  "ppc-BlindSubmitBody": BlindSubmitBody,
  "ppc-Heading": "양식을 기입해 주셔서 감사합니다. 문서가 첨부된 이메일을 보내드렸습니다. 아래를 클릭하여 지금 직접 다운로드할 수 있습니다.",
  "ppc-Body": "",
  "ppc-Download": Download,
  "case-study-BlindSubmitHeading": BlindHeading,
  "case-study-BlindSubmitBody": BlindSubmitBody,
  "case-study-Heading": "저희 콘텐츠에 관심을 가져주셔서 감사합니다. 아래 버튼을 클릭하여 다운로드를 시작하십시오.",
  "case-study-Body": Body,
  "case-study-Download": "지금 다운로드",
  "video-BlindSubmitHeading": "Thank you for your interest in our content. Click the button below to watch the video.",
  "video-BlindSubmitBody": "MasterControl의 모든 독점 문서 및 비디오에 대한 액세스를 잠금 해제했습니다.리소스 센터 방문",
  "video-Heading": Heading,
  "video-Body": "",
  "video-Download": Download,
  "video-landing-page-BlindSubmitHeading": "Thank you for your interest in our content. Click the button below to watch the video.",
  "video-landing-page-BlindSubmitBody": "MasterControl의 모든 독점 문서 및 비디오에 대한 액세스를 잠금 해제했습니다.리소스 센터 방문",
  "video-landing-page-Heading": Heading,
  "video-landing-page-Body": "",
  "video-landing-page-Download": Download,
  "recommended-resources-BlindSubmitHeading": "",
  "recommended-resources-BlindSubmitBody": "",
  "recommended-resources-Heading": "MasterControl의 모든 독점 문서 및 비디오에 대한 액세스를 잠금 해제했습니다.",
  "recommended-resources-Body": " Download these additional recommended documents now or visit our",
  "recommended-resources-Download": "리소스 센터",
  "general-modal-BlindSubmitHeading": "",
  "general-modal-BlindSubmitBody": "",
  "general-modal-Heading": "귀하가 요청한 리소스",
  "general-modal-Body": "귀하가 요청한 리소스. 아래 버튼을 클릭하여 다운로드를 시작하십시오.",
  "general-modal-Download": ""
};
export default PostFormFieldMap;